// @flow strict
import React from 'react';
import {Link} from 'gatsby';
import Author from './Author';
import Comments from './Comments';
import Content from './Content';
import Meta from './Meta';
import Tags from './Tags';
import styles from './Post.module.scss';
import type {Node} from '../../types';

type Props = {
  post: Node
};

const Post = ({post}: Props) => {
  const {html} = post;
  const {tagSlugs, slug} = post.fields;
  const {tags, title, date, podcast, credits} = post.frontmatter;
  console.log(credits)

  return (
    <div className={styles['post']}>
      <Link className={styles['post__home-button']} to="/">All Articles</Link>

      <div className={styles['post__content']}>
        <Content body={html} title={title}/>
        <div style={{textAlign:"center"}}>
        <audio id="audio_with_controls"
               controls
               controlsList="nodownload"
               src={`/podcast/${podcast}`}
               type="audio/mp3"
        >
          Ihr Browser kann dieses Tondokument nicht wiedergeben.<br/>
          Sie können es unter
          <a href={`/podcast/${podcast}`}>diesem Link</a>
          abrufen.
        </audio>
        </div>
      </div>

      <div className={styles['post__footer']}>
        <Meta date={date}/>
        {tags && tagSlugs && <Tags tags={tags} tagSlugs={tagSlugs}/>}
        <Author/>
      </div>

      <div className={styles['post__footer']} style={{fontSize: "xx-small"}}><pre><code>{credits ?? ""}</code></pre></div>

      <div className={styles['post__comments']}>
        <Comments postSlug={slug} postTitle={post.frontmatter.title}/>
      </div>
    </div>
  );
};

export default Post;
